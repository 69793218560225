import React from 'react'
import Swal from 'sweetalert2'

const CompraEfectiva = () => {
  return (
    <div>
          {
           
          }
    </div>
  )
}

export default CompraEfectiva