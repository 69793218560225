import React from "react";
import GoogleMaps from "simple-react-google-maps";

export default function Map() {
  return (
    <div style={{height: "100%"}}>
      <GoogleMaps
        apiKey='AIzaSyC6y6Y-EvIaBAi4XGwzDF56ZRwSfcrm2ms'
        style={{ height: "100%", width: "100%" }}
        zoom={5}
        center={{lat:-1.058502275651871, lng:-79.43648420740728}}
        markers={[
            {lat: -2.141973065270933, lng: -79.86585832698131},
            {lat:-2.109707498117745, lng:-79.8720813576718},
            {lat:-2.1666067438898273, lng:-79.90544664418222},
            {lat:-2.058043139794229, lng:-79.87067298650933},
            {lat:-2.177548514987746, lng:-79.94071385582046},
            {lat:-2.130316564375991, lng:-79.86799294232709},
            {lat:-2.073399254730997, lng:-79.84616608852821},
            {lat:-2.1570024698732877, lng:-79.89218845581776},
            {lat:-2.1568738150615325, lng:-79.89230647301866},
            {lat:-2.131781978849826, lng:-79.86355998465419},
            {lat:-2.145628880301326, lng:-79.88285867318244},
            {lat:-2.157754546030025, lng:-79.88907484599474},
            {lat:-2.0568620273150318, lng:-79.92099648650932},
            {lat:-2.0748681767798325, lng:-79.85412176930842},
            {lat:-0.1679518529956743, lng:-78.49144454232712}
        ]} //optional
      />
    </div>
  );
}
